


.heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) { /* target devices with screen widths up to 768px */
    .heading-container h1 {
        font-size: 1.5em; /* adjust the font size for smaller screens */
    }
}


.input-container {
  display: flex;
  position: relative;
}

.input-box {
  flex-basis: 60%; /* 20% of the screen width */
  margin: 0 10px;
  padding: 12px 20px;
  width: 50%;
  align-content: center;
  text-align: center;
    



@media only screen and (max-width: 768px) { /* target devices with screen widths up to 768px */
  .input-box {
      flex-basis: 50%; /* take up the full width of the container */
      margin-bottom: 10px;
  }
 }

}


  .inputsifs {
      flex-basis: 30%; /* take up the full width of the container */
      width: 30%;
      
     
      margin-top: 10px;
      margin-left: 10px;

      display: flex;
      justify-content: left;
      align-items: left;
      position: relative;
      flex-direction: column;
  }

  .words {
    margin-top: 20px;
    display: flex;
    flex-basis: 3%;
    height: 80px;
    margin-left: 18px;
    width: 380px;
    
  }

  .recordings {
    margin-top: 30px;
    display: flex;
    flex-basis: 30%;
    margin-left: 20px;
    flex-direction: column;
    text-align: center;
    
    

  }

  .middleContainer{
    display: flex;
    position: relative;
    overflow: auto;
    min-height: 0;
    display: flex;
    flex-wrap: wrap;
    

  }

  .firstTwo{
    display: flex;
    position: relative;
    overflow: auto;
    min-height: 0;
    display: flex;
    flex-wrap: wrap;
    

  }

  .counts {
    display: flex;
    position: relative;
    width: 30%;
    flex: 1;
    flex-basis: 30%; /* 20% of the screen width */
    margin-top: 30px;
    text-align: center;
    flex-direction: column;

  }

  .headings{
    
    display: flex;
    position: relative;
  }

  .headings-2{
    
    display: flex;
    position: relative;
  }

  .headings-3{
    
    display: flex;
    position: relative;
  }

  .headings-4{
    
    display: flex;
    position: relative;
  }

  .headings-5{
    
    display: flex;
    position: relative;
  }

  .headings-6{
    
    display: flex;
    position: relative;
  }

  .headings-7{
    
    display: flex;
    position: relative;
  }

  .headings-8{
    
    display: flex;
    position: relative;
  }

  .headings-9{
    
    display: flex;
    position: relative;
  }

  .heading-1 {
    /* set the display property to inline-block */
    display: inline-block;
    /* set the width of each h1 element to 50% of the container */
    width: 30%;
    text-align: center;
    margin: 0;
    flex-basis: 30%; /* 20% of the screen width */
    margin: 0 10px;
  }

   .heading-2 {
    /* set the display property to inline-block */
    display: inline-block;
    /* set the width of each h1 element to 50% of the container */
    width: 30%;
    text-align: center;
    flex-basis: 30%; /* 20% of the screen width */
    margin: 0 10px;
    
  }

  .heading-3 {
    /* set the display property to inline-block */
    display: inline-block;
    /* set the width of each h1 element to 50% of the container */
    width: 30%;
    text-align: center;
    flex-basis: 30%; /* 20% of the screen width */
   margin: 0 10px;
    
  }

  .spaced-word {
  /* increase the letter-spacing by 2px */
  letter-spacing: 10px;
}

.countHeadings{
  display: flex;
 
}
 

.wordOne{
  margin-left: 10%;
}
.wordThree{
  margin-left: 20%;
}

.wordTwo{
  margin-left: 25%;
}

.save-btn {
  background-color: #599d9c; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 12px 32px; /* Some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Add some margin */
  cursor: pointer; /* Add pointer cursor on hover */
  transition: background-color 0.3s ease; /* Add transition effect on hover */
  border-radius: 5px;
}
.save-btn:hover {
  background-color: #3E8E41; /* Darker green background on hover */
}
  
.headingRecording{
   font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin: 0em 0;
    color: #333;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

  
  .first{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: #333;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
  
  




  .tk {
    display: flex;
    justify-content: center;
  }
  
  .nastyc {
    text-align: center;
  }
  
  





